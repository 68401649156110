.wrap {
    display:flex;
    flex-flow: column;
    border:2px #ccc solid;
    min-width: 750px;
    height:500px;
    margin:0 0 1em;
  }

#child1 {
    background-color: silver;
    text-align: center;
}

#child2 {
   background-color: transparent;
}

#countdown {
    color: #ff7f50;
    font-weight: bold;
    margin: 16px;

}

button {
    margin: 16px;
}

.videobnt {
   display:none;
}

@media (min-width: 600px) {
    #parent {
        display: flex;
    }
    #child1 {
        flex-grow: 3;
    }
    #child2 {
        flex-grow: 1;
    }
}

video {
        width: 100%;
        /*height: 500px;*/
        object-fit: cover;
}

.in-video {
        width: 320px;
        height: 200px;
        object-fit: cover;
}

